// axios 配置
import axios from 'axios'
import GLOBAL from '../config/config'
import router from '../router'

let $ajax = axios.create({
  baseURL: GLOBAL.WEB_SITE + '/api/',
  timeout: 30000
})

// http request 拦截器
$ajax.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token')
    config.data = JSON.stringify(config.data)
    config.headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    if (token) {
      config.params = {'token': token}
    }
    // console.log(config)
    return config
  },
  err => {
    return Promise.reject(err)
  }
)

// http respone 拦截器
$ajax.interceptors.response.use(
  response => {
    if (response.data.code == -9) {
      router.push('/login')
    }
    return response
  },
  error => {
    return Promise.reject(error.response.data)
  }
)

/**
 * get 请求方法
 * @param url
 * @param data
 * @return {Promise}
 */
export function get (url, params = {}) {
  return new Promise((resolve, reject) => {
    $ajax.get(url, {
      param: params
    }).then(response => {
      resolve(response.data)
    }, err => {
      reject(err)
    })
  })
}

/**
 * post 请求方法
 * @param url
 * @param data
 * @return {Promise}
 */
export function post (url, data = {}) {
  return new Promise((resolve, reject) => {
    $ajax.post(url, data).then(response => {
      resolve(response.data)
    }, err => {
      reject(err)
    })
  })
}

/**
 * patch 请求方法
 * @param url
 * @param data
 * @return {Promise}
 */
export function patch (url, data = {}) {
  return new Promise((resolve, reject) => {
    $ajax.patch(url, data).then(response => {
      resolve(response.data)
    }, err => {
      reject(err)
    })
  })
}

/**
 * put 请求方法
 * @param url
 * @param data
 * @return {Promise}
 */
export function put (url, data = {}) {
  return new Promise((resolve, reject) => {
    $ajax.put(url, data).then(response => {
      resolve(response.data)
    }, err => {
      reject(err)
    })
  })
}
